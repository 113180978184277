import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import p1 from 'assests/Photos/SchoolProgram/2023/RajyotsavaDay/1.jpg';
import p2 from 'assests/Photos/SchoolProgram/2023/RajyotsavaDay/2.jpg';
import p3 from 'assests/Photos/SchoolProgram/2023/RajyotsavaDay/3.jpg';
import p4 from 'assests/Photos/SchoolProgram/2023/RajyotsavaDay/4.jpg';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/SchoolProgram2023/components';

const RajyotsavaDay2023 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const photos = [
          {
                    src: p2,
                    source: p2,
                    rows: 1,
                    cols: 2,
                },
                {
                    src: p1,
                    source: p1,
                    rows: 1,
                    cols: 2,
                },
                {
                    src: p3,
                    source: p3,
                    rows: 1,
                    cols: 2,
                },
      {
          src: p4,
          source: p4,
          rows: 1,
          cols: 2,
      },
     
      
      
    
     
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                Rajyotsava Day  	
                                </Typography>
                                {/* <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: Pre-Primary   Date: 12 OCTOBER 2023
                                </Typography> */}

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                Kannada Rajyotsava was celebrated on Thursday 9 November2023 with pomp and splendour by the students of National Public School, Yeshwanthpur. The school wore a festive look adorned with yellow and red colours representing the Karnataka flag. 
                                    <br></br>
                                    The celebration started with lighting the lamp followed by a speech, song, skit, mono-act and a folk dance in Kannada. It highlighted the proud legacy of our state.    
                                    <br></br>
                                    The Principal exhorted the students to be proud of the exuberant culture of multilingual ethnicity of the state and contribute towards the progress of the State and society. 
                    
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                                fontFamily={' normal'}
                            ><br />
                                “KANNADAVE   SATHYA                        KANNADAVE NITHYA”

                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 400 : 200}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Box marginBottom={4}>
                            <Sidebar2023/>
                        </Box> 
                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default RajyotsavaDay2023;